function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
}

function phoneMask() {
    const phoneMasks = document.querySelectorAll('.phone-mask');

    if (typeof new Inputmask() === 'object') {
        const phoneMask = new Inputmask('+7 ( 999 )  999  99  99', {
            showMaskOnFocus: false,
            showMaskOnHover: false
        });
        phoneMasks && phoneMask.mask(phoneMasks);
    }
}

function setFilled() {
    let text = document.querySelectorAll('.iText');
    text.forEach((el) => {
        if (el.classList.contains('pmask')) {
            el.parentNode.classList.add('filled');
        }
        if (el.value.length > 0) {
            el.parentNode.classList.add('filled');
        } else {
            el.parentNode.classList.remove('filled');
        }
        el.addEventListener('focus', () => {
            el.parentNode.classList.add('filled');
        });
        el.addEventListener('blur', () => {
            if (el.value.length === 0) {
                el.parentNode.classList.remove('filled');
            }
        })
    });
}

function scrolledEl() {
    let els = document.querySelectorAll('.scrolled_el');
    if (els.length) {
        els.forEach(el => {
            setTimeout(function () {
                new SimpleBar(el, {
                    autoHide: false,
                });
            });
        })
    }
}

function closeWindow() {
    let menu = document.getElementById('main_menu');
    if (menu.classList.contains('active')) {
        toggleMenu(document.querySelector('.menu-btn'));
    }
}

function bg() {
    let bg = document.querySelector('.mask--bg');
    bg.classList.toggle('active');
}

function toggleMenu(btn) {
    let menu = document.getElementById('main_menu');
    btn.classList.toggle('close');
    menu.classList.toggle('active');
    document.body.classList.toggle('_lock');
    bg();
}

function videoPlay(btn) {
    let video = btn.previousElementSibling;
    if (video.nodeName === 'VIDEO') {
        btn.classList.add('hide');
        video.play();
    }
}


function mainParsleyValid() {
    let form = document.getElementById('callback_main_form');
    $(form).parsley().on('form:success', function () {
        setTimeout(function () {
            form.reset();
        }, 1000);
    });
    $(form).parsley().validate();
}

function validateQuestionForm() {
    let form = document.getElementById('question_form');
    if (form) {
        form.addEventListener('submit', (e) => {
            e.preventDefault();
        });
        $(form).parsley().on('form:success', function () {

            $.ajax({
                url: $(form).attr('action'),
                method: 'post',
                data: $(form).serialize(),
                dataType: 'json',
                success: function (data) {

                    if (data['status'] == 'success') {
                        form.reset();
                        $.fancybox.close();
                        $.fancybox.open({
                            src: '#question_form_success',
                            opts: {
                                autoFocus: false,
                                touch: false,
                                'smallBtn': false,
                                toolbar: false,
                                hideScrollbar: false,
                            }
                        });
                    }
                }
            });
        });
    }
}

function sendFeedback() {
    let form = document.getElementById('feedback_main_form');
    $(form).parsley().on('form:success', function () {
        $.ajax({
            url: $(form).attr('action'),
            method: 'post',
            data: $(form).serialize(),
            dataType: 'json',
            success: function (data) {

                if (data['status'] == 'success') {
                    $.fancybox.close();
                    $.fancybox.open(
                        {
                            src: '#feedback_modal_success',
                            opts: {
                                autoFocus: false,
                                touch: false,
                                'smallBtn': false,
                                toolbar: false,
                                hideScrollbar: false,
                            }
                        }
                    );
                    setTimeout(function () {
                        form.reset();
                    }, 1000);
                }
            }
        });
    });
    $(form).parsley().validate();
}

function modalParsleyValid() {
    let form = document.getElementById('modal_callback_form'),
        success = document.querySelector('.modal--callback-success');
    $(form).parsley().on('form:success', function () {
        $.ajax({
            url: $(form).attr('action'),
            method: 'post',
            data: $(form).serialize(),
            dataType: 'json',
            success: function (data) {

                if (data['status'] == 'success') {
                    success.classList.add('active');
                    setTimeout(function () {
                        form.reset();
                        setFilled();
                        success.classList.remove('active');
                    }, 5000);
                }
            }
        });
    });
    $(form).parsley().validate();
}

function eventsSlider() {
    let sliders = document.querySelectorAll('.esl'),
        enable = false;
    if (sliders.length) {
        document.addEventListener('DOMContentLoaded', function () {
            if (window.innerWidth < 768 && !enable) {
                sliders.forEach(el => {
                    let data = el.dataset.slider;
                    let sli = $(el).slick({
                        arrows: true,
                        infinite: true,
                        speed: 1500,
                        prevArrow: $('.slider--left[data-slider="' + data + '"]'),
                        nextArrow: $('.slider--right[data-slider="' + data + '"]'),
                        adaptiveHeight: true,
                        variableWidth: true,
                    });
                    let maxH = 0;
                    sli.find('.event--card-wrap').each(function (index, el) {
                        maxH = el.clientHeight > maxH ? el.clientHeight : '';
                    });
                    sli.find('.event--card-wrap').css('height', maxH + sli.find('.event--price-wrapper').height() + 12 + 'px');
                });
                enable = true;
            }
        });
        window.addEventListener('resize', () => {
            if (window.innerWidth < 768 && !enable) {
                sliders.forEach(el => {
                    let data = el.dataset.slider;
                    let sli = $(el).slick({
                        arrows: true,
                        infinite: true,
                        speed: 1500,
                        prevArrow: $('.slider--left[data-slider="' + data + '"]'),
                        nextArrow: $('.slider--right[data-slider="' + data + '"]'),
                        adaptiveHeight: true,
                        variableWidth: true,
                    });
                    let maxH = 0;
                    sli.find('.event--card-wrap').each(function (index, el) {
                        maxH = el.clientHeight > maxH ? el.clientHeight : '';
                    });
                    sli.find('.event--card-wrap').css('height', maxH + sli.find('.event--price-wrapper').height() + 12 + 'px');
                });
                enable = true;
            } else if (window.innerWidth >= 768 && enable) {
                sliders.forEach(el => {
                    $(el).slick('unslick');
                });
                enable = false;
            }
        })
    }


    ; (function () {
        let eventsDetailSlider = $('.events-slider--wrap').slick({
            arrows: true,
            infinite: true,
            speed: 1500,
            prevArrow: $('.slider--left'),
            nextArrow: $('.slider--right'),
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 641,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
        let maxH = 0;
        eventsDetailSlider.find('.event--card-wrap').each(function (index, el) {
            if (el.clientHeight > maxH) {
                maxH = el.clientHeight;
            }
        });
        eventsDetailSlider.css('minHeight', maxH);
        $('.event--card-wrap').css('minHeight', maxH);
    })();

}

function reviewsSlider() {
    let slider = document.querySelector('.reviews--main');
    if (slider) {
        $(slider).slick({
            dots: false,
            arrows: true,
            infinite: true,
            speed: 1500,
            slidesToShow: 2,
            prevArrow: $('#reviews_left'),
            nextArrow: $('#reviews_right'),
            autoplay: false,
            slidesToScroll: 2,
            variableWidth: false,
            rows: 0,
            centerPadding: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: false,
                        centerMode: true,
                        centerPadding: 32
                        // arrows: true,
                    }
                },
            ]
        });
    }
}

function authOpen() {
    $.fancybox.close();
    $.fancybox.open(
        {
            src: '#modal_auth',
            opts: {
                autoFocus: false,
                touch: false,
                'smallBtn': false,
                toolbar: false,
                hideScrollbar: false,
                afterClose() {
                    clearForm(document.getElementById('modal_enter'));
                }
            }
        }
    );
}

function modalCallback() {
    $.fancybox.close();
    $.fancybox.open(
        {
            src: '#modal_callback',
            opts: {
                autoFocus: false,
                touch: false,
                'smallBtn': false,
                toolbar: false,
                hideScrollbar: false,
            }
        }
    );
}
function modalBasket() {
    $.fancybox.close();
    $.fancybox.open(
        {
            src: '#modal_add-basket',
            opts: {
                autoFocus: false,
                touch: false,
                'smallBtn': false,
                toolbar: false,
                hideScrollbar: false,
            }
        }
    );
}

function toggleOnline(btn, id) {
    let block = document.querySelector('.online--main-wrap[data-online="' + id + '"]'),
        childrenBtn = btn.children[0];
    block.classList.toggle('active');
    $(block).slideToggle();
    btn.classList.toggle('active');
    btn.parentNode.classList.toggle('active');
    if (btn.classList.contains('active')) {
        childrenBtn.textContent = "Свернуть";
    } else {
        childrenBtn.textContent = childrenBtn.dataset.name;
    }
}

function AuthTabs(btn) {
    let btns = document.querySelectorAll('.auth--btn');
    btns.forEach(el => {
        if (el !== btn) {
            el.classList.remove('active');
        } else {
            el.classList.add('active');
        }
    });
}

function customSelectBlocks() {
    let selects = document.querySelectorAll('.cstm--select');
    if (selects.length) {
        selects.forEach(el => {
            customSelect(el);
            try {
                el.children.forEach((opt, index) => {
                    if (opt.dataset.price && opt.dataset.priceOld) {
                        let dataValue = el.customSelect.panel.children[index].dataset.value;
                        el.customSelect.panel.children[index].innerHTML = `
                            <span class="select--text">${dataValue}</span>
                            <span class="select--wrapper">
                                <span class="select--price-price event--price-price">${(+opt.dataset.price).toLocaleString()} руб.</span>
                                <span class="select--price-old event--price-old">${(+opt.dataset.priceOld).toLocaleString()} руб.</span>
                            </span>
                        `;
                    }
                    else {
                        return false;
                    }
                })
            } catch (e) {
                //nothing
            }
        });
    }

    ; (function () {
        const customCallbackSelect = document.getElementById('custom_callback_select');
        if (customCallbackSelect) {
            const select = customSelect(customCallbackSelect);
            select[0].opener.innerHTML = '<span>Выберите направление</span>';
            select[0].select.children.forEach((opt, index) => {
                if (opt.dataset.price && opt.dataset.priceOld) {
                    let dataValue = select[0].select.children[index].value;
                    select[0].select.customSelect.panel.children[index].innerHTML = `
                            <span class="select--text">${dataValue}</span>
                            <span class="select--wrapper">
                                <span class="select--price-price event--price-price">${(+opt.dataset.price).toLocaleString()} руб.</span>
                                <span class="select--price-old event--price-old">${(+opt.dataset.priceOld).toLocaleString()} руб.</span>
                            </span>
                        `;
                }
            });
        }
    })();

}

function gallerySlider() {
    let slider = document.querySelector('.about--gallery-main');
    window.sliderActive = false;

    function initSlider(slider) {
        if (slider) {
            if (!window.sliderActive) {
                window.sliderActive = true;
                $(slider).slick({
                    dots: false,
                    arrows: false,
                    infinite: true,
                    speed: 1500,
                    slidesToShow: 8,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    slidesToScroll: 4,
                    adaptiveHeight: true,
                    variableWidth: false,
                    rows: 0,
                    centerPadding: 0,
                    prevArrow: $('.slider--left'),
                    nextArrow: $('.slider--right'),
                    responsive: [
                        {
                            breakpoint: 990,
                            settings: {
                                arrows: true,
                                slidesToShow: 6,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                arrows: true,
                                slidesToShow: 2,
                            }
                        },
                    ]
                });
            } else if (window.sliderActive) {
                window.sliderActive = false;
            }
        }
    }

    document.addEventListener('DOMContentLoaded', function () {
        initSlider(slider);
    });
}

function openEvent() {
    $.fancybox.close();
    $.fancybox.open(
        {
            src: '#modal_event',
            opts: {
                autoFocus: false,
                touch: false,
                'smallBtn': false,
                toolbar: false,
                hideScrollbar: false,
                afterShow: function (instance, current) {
                    scrolledEl();
                },
            }
        }
    );
}

function openForgotPass() {
    $.fancybox.close();
    $.fancybox.open({
        src: '#modal_forgot-pass',
        opts: {
            autoFocus: false,
            touch: false,
            'smallBtn': false,
            toolbar: false,
            hideScrollbar: false,
            afterClose() {
                clearForm(form);
                clearForm(phoneForm);
            }
        }
    });

    const forgotBtn = document.querySelector('#modal_forgot .more--btn');
    const form = document.getElementById('modal_forgot');
    const success = document.querySelector('#modal_forgot-pass .modal--forgot-success');
    const phoneForm = document.getElementById('forgot_form_phone');


    if (forgotBtn && form && success) {
        forgotBtn.addEventListener('click', function (e) {
            $(form).parsley().on('form:success', function () {
                success.classList.add('active');
                form.reset();
                setFilled();
            });
            $(form).parsley().validate();
        });
    }

    if (phoneForm) {
        new Inputmask('999999', { showMaskOnHover: false }).mask(phoneForm.querySelector('input[type=tel]'));
        phoneForm.addEventListener('submit', function (e) {
            e.preventDefault();
            e.submitter.textContent = 'Отправить код повторно через 60 сек';
            clearForm(this);
            e.submitter.disabled = true;
            let timestart = 60;
            const interval = setInterval(() => {
                timestart--;
                if (timestart == 0) {
                    clearInterval(interval);
                    e.submitter.disabled = false;
                    e.submitter.textContent = 'Подтвердить';
                }
                else {
                    e.submitter.textContent = 'Отправить код повторно через ' + timestart + ' сек';
                }
            }, 1000);
        });
    }
}


function initRegisterForm() {
    const registerSelect = document.querySelectorAll('#register-form .custom-select-panel');
    if (registerSelect) {
        registerSelect.forEach(el => {
            new SimpleBar(el, {
                autoHide: false,
            });
        })
    }
    if (Datepicker) {
        // $('#register-date').datepicker();

        new Datepicker('#register-date', {
            i18n: {
                months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                time: ['Время', 'Старт', 'Конец']
            },
            templates: {

                header: [
                    '<header class="datepicker__header">',
                    '<span class="datepicker__title"><%= renderMonthSelect() %></span>',
                    '<span class="datepicker__title"><%= renderYearSelect() %></span>',
                    '</header>'
                ].join(''),
            },

            weekStart: 1,
            onChange: function (d) {
                if (d && this._el.value !== "") {
                    return this.node.classList.add('filled');
                }
            },
            yearRange: 90
        });
    }
}

function validateRegisterForm(e) {
    const form = document.getElementById('register-form');
    if (form) {
        $(form).parsley().on('form:success', function () {
            $.ajax({
                url: $(form).attr('action'),
                method: 'post',
                data: $(form).serialize(),
                dataType: 'json',
                success: function (data) {

                    if (data['status'] == 'success') {
                        form.reset();
                        $.fancybox.close();
                        $.fancybox.open({
                            src: '#modal-register',
                            opts: {
                                autoFocus: false,
                                touch: false,
                                'smallBtn': false,
                                toolbar: false,
                                hideScrollbar: false,
                            }
                        });
                    }
                }
            });
        });
        $(form).parsley().validate();
    }
    return;
}
/*
function validateAuthForm(e) {
    const form = document.getElementById('modal_enter');
    if (form) {
        $(form).parsley().on('form:success', function () {
            window.location.href = '/cabinet/profile';
        });
        $(form).parsley().validate();
    }
    return;
}*/

function castomScroll(el) {
    const block = document.querySelectorAll(el);
    if (block) block.forEach((e) => new SimpleBar(e));
}
function changeCountry() {
    const input = document.getElementById('country');
    if (input) {

        castomScroll('.drop-help__list')
        input.addEventListener('input', (e) => {
            console.log(e.target.value);
            const val = e.target.value
            if (val.length > 2) {

                $.ajax({
                    url: 'https://domkadoni.dooit.pro/api/region/find-country',
                    method: 'post',
                    data: { search: val },
                    dataType: 'json',
                    success: function (response) {
                        console.log(response);

                        let listHtml = $('.country-box .drop-help__list .simplebar-content')
                        listHtml.html('')
                        if (response.length > 0) {
                            dropRegion()
                            $('.country-box').addClass('_active')
                            response.forEach(e => {
                                listHtml.append(`<div>${e.name}</div>`)
                            })
                            setTimeout(() => {
                                dropRegion()
                            }, 300)
                        }
                    }
                });
            } else {
                let listHtml = $('.country-box .drop-help__list .simplebar-content')
                $('.country-box').removeClass('_active')
                listHtml.html('')
            }

        });
    }
}

function changeCity() {
    const input = document.getElementById('city');
    if (input) {
        castomScroll('.drop-help__list')

        input.addEventListener('input', (e) => {
            console.log(e.target.value);
            const val = e.target.value
            if (val.length > 1) {
                $.ajax({
                    url: 'https://domkadoni.dooit.pro/api/region/find-city',
                    method: 'post',
                    data: { search: val },
                    dataType: 'json',
                    success: function (response) {
                        console.log(response);


                        if (response.length > 0) {
                            dropRegion()
                            let listHtml = $('.city-box .drop-help__list .simplebar-content')

                            $('.city-box').addClass('_active')
                            response.forEach(e => {
                                listHtml.append(`<div>${e.name}</div>`)
                            })
                            setTimeout(() => {
                                dropRegion()
                            }, 300)
                        }
                    }
                });
            } else {
                let listHtml = $('.city-box .drop-help__list .simplebar-content')
                $('.city-box').removeClass('_active')
                listHtml.html('')
            }


        });
    }
}

function infoProfil() {
    const form = document.getElementById('profile_personal_info');
    if (form) {
        $(form).parsley().on('form:success', function () {
            $.ajax({
                url: $(form).attr('action'),
                method: 'post',
                data: $(form).serialize(),
                dataType: 'json',
                success: function (data) {

                    if (data['status'] == 'success') {
                        form.reset();
                        // $.fancybox.close();
                        // $.fancybox.open({
                        //     src: '#modal-register',
                        //     opts: {
                        //         autoFocus: false,
                        //         touch: false,
                        //         'smallBtn': false,
                        //         toolbar: false,
                        //         hideScrollbar: false,
                        //     }
                        // });
                    }
                }
            });
        });
        $(form).parsley().validate();
    }
    return;
}

function makeAnPopupOpen() {
    // todo будет наверное запрос на сервер на запись
    $.ajax({
        url: '',
        data: '',
        success: function () {
            $.fancybox.close();
            $.fancybox.open({
                src: '#modal_make-an',
                opts: {
                    autoFocus: false,
                    touch: false,
                    'smallBtn': false,
                    toolbar: false,
                    hideScrollbar: false,
                }
            });
        },
        error: function (err) {
            console.warn(err)
        }
    });
}

function breadcrumbs() {
    let breadcrumbs = document.querySelector('.breadcrumbs');
    let d = document.createElement('div');
    d.classList.add('breadcrumbs--dots');
    d.textContent = '...';

    if (breadcrumbs) {
        window.addEventListener('resize', function () {
            if (breadcrumbs.clientWidth < breadcrumbs.scrollWidth) {
                breadcrumbs.parentElement.style.position = 'relative';
                breadcrumbs.insertAdjacentElement('afterend', d);
            }
            else if (d) {
                d.remove();
            }
        });
    }
}

function animateBottomReviews() {
    $('#reviews__link-bottom').click(function () {
        if (Object.values($('#reviews__form')).length !== 0) {
            $('html, body').animate({
                scrollTop: $('#reviews__form').offset().top
            }, 1000);
        }
        else {
            authOpen();
        }
    });
}

function animateTopDocument() {
    $('#btn_position_top').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });
    $(document).scroll(function () {
        if (scrollY > 600) {
            $('#btn_position_top').show();
        }
        else {
            $('#btn_position_top').hide();
        }
    });
}

function setCookie() {
    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    let cookie = getCookie('confirm_cookie');
    if (typeof cookie === "undefined") {
        $.fancybox.close();
        $.fancybox.open({
            src: '#cookie_modal',
            opts: {
                autoFocus: false,
                touch: false,
                'smallBtn': false,
                toolbar: false,
                hideScrollbar: false,
                afterClose() {
                    return (function () {
                        let date = new Date(Date.now() + 21 * 24 * 60 * 60 * 1000);
                        date = date.toUTCString();
                        return document.cookie = "confirm_cookie=" + 1 + "; expires=" + date + "; path=/";
                    })();
                }
            }
        });
    }
}

function resize(callback) {
    return window.addEventListener('resize', callback);
}

function clearForm(form) {
    if (form) {
        const elements = form.querySelectorAll('.filled') || [];
        [].forEach.call(elements, (el) => el.classList.remove('filled'));
        form.reset();
    }
    return;
}

// todo переместить ниже скрипт в шаблон компонента
document.addEventListener('DOMContentLoaded', function () {
    ; (function initStaticHeghtPersonallyItems() {
        var items = document.querySelectorAll('.personally__item');
        if (items) {
            var itemHeight = [];
            [].forEach.call(items, function (item) {
                var descr = item.querySelector('.personally__descr');
                itemHeight.push(descr.clientHeight);
            });
            [].forEach.call(items, function (item) {
                var descr = item.querySelector('.personally__descr');
                descr.style.height = getMaxOfArray(itemHeight) + 'px';
            });
        }
        resize(function () {
            if (items) {
                var itemHeight = [];
                [].forEach.call(items, function (item) {
                    var descr = item.querySelector('.personally__descr');
                    descr.style.height = '';
                });
                [].forEach.call(items, function (item) {
                    var descr = item.querySelector('.personally__descr');
                    itemHeight.push(descr.clientHeight);
                });
                [].forEach.call(items, function (item) {
                    var descr = item.querySelector('.personally__descr');
                    descr.style.height = getMaxOfArray(itemHeight) + 'px';
                });
            }
        });
    })();
});

function initModalVideo() {
    const links = document.querySelectorAll('[data-popup-video]');
    if (links) {
        $('[data-popup-video]').fancybox({
            toolbar: false,
            baseTpl:
                '<div class="fancybox-container video-modal-window" role="dialog" tabindex="-1">' +
                '<div class="fancybox-bg"></div>' +
                '<div class="fancybox-inner">' +
                '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
                '<div class="fancybox-toolbar">{{buttons}}</div>' +
                '<div class="fancybox-navigation">{{arrows}}</div>' +
                '<div class="fancybox-stage"></div>' +
                '<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' +
                '</div>' +
                '</div>',
            iframe: {
                preload: true,
                tpl: '<iframe id="fancybox-frame{rnd}" name="fancybox-frame{rnd}" class="fancybox-iframe" allowfullscreen allow="autoplay; fullscreen" src=""></iframe>' +
                    '<button data-fancybox-close="" class="fancybox-button fancybox-button--close" title="Close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"></path></svg></button>'
            },
        });
    }
}

function initProfileForms() {
    if (window.Parsley) {
        window.Parsley.addValidator('maxFileSize', {
            validateString: function (_value, maxSize, parsleyInstance) {
                const files = parsleyInstance.$element[0].files;
                return files.length != 1 || files[0].size <= maxSize * 1024;
            },
            requirementType: 'integer',
            messages: {
                en: 'Размер фото не более 300 Кб',
            }
        });
        window.Parsley.addValidator('acceptFiles', {
            validateString: function (_value, acceptTypes, parsleyInstance) {
                const file = parsleyInstance.$element[0].files[0].type;
                return acceptTypes.includes(file);
            },
            requirementType: 'string',
            messages: {
                en: 'Фото с расширением (.jpg, .jpeg, .png)',
            }
        });
    }

    // const personalForm = document.querySelector('#profile_personal_info');
    // if (personalForm) {
    //     personalForm.addEventListener('submit', e => {
    //         e.preventDefault();
    //     });
    //     $(personalForm).parsley().on('form:success', function () {
    //         console.dir(personalForm);
    //         personalForm.reset();
    //     });
    // }
    // const personalAbout = document.querySelector('#profile_personal_about');
    // if (personalAbout) {
    //     personalAbout.addEventListener('submit', e => {
    //         e.preventDefault();
    //     });
    //     $(personalAbout).parsley().on('form:success', function () {
    //         console.dir(personalAbout);
    //         personalAbout.reset();
    //     });
    // }

    const registerSelect = document.querySelectorAll('#profile_personal_info .custom-select-panel');
    if (registerSelect) {
        registerSelect.forEach(el => {
            new SimpleBar(el, {
                autoHide: false,
            });
        })
    }


    if (Datepicker) {
        function getYearDate(date) {
            let now = new Date();
            let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            let dob = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            let dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
            let age;
            age = today.getFullYear() - dob.getFullYear();
            if (today < dobnow) {
                age = age - 1;
            }
            return age;
        }
        new Datepicker('#profile_date', {
            i18n: {
                months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                time: ['Время', 'Старт', 'Конец']
            },
            weekStart: 1,
            onChange: function (d) {
                if (d && this._el.value !== "") {
                    this._el.value += ` / ${getYearDate(new Date(this._selected[0]))} лет`;
                }
            },
        });
    }
}
function closeFancybox() {
    const btns = document.querySelectorAll('.btn--close')
    if (btns)
        for (let i = 0; i < btns.length; i++) {
            // btns[i].addEventListener('click', () => {
            document.querySelectorAll('.fancybox-container').forEach(e => e.remove())
            // })

        }
}
//таймер
function timerInt() {
    const blocks = document.querySelectorAll('.price-timer')
    if (blocks)
        for (let i = 0; i < blocks.length; i++) {
            const timeHtml = blocks[i]

            if (timeHtml.dataset.unix) {
                const targetDate = +timeHtml.dataset.unix * 1000;

                function startCountdown() {
                    const currentDate = new Date().getTime();
                    const timeRemaining = targetDate - currentDate;

                    // let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
                    let hours = Math.floor(
                        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    );
                    let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

                    timeHtml.textContent = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
                }
            }
            setInterval(startCountdown, 1000);
        }
}

function dropRegion() {
    const blocks = document.querySelectorAll('.drop-help')
    for (const block of blocks) {
        const input = block.querySelector('input'),
            list = block.querySelectorAll('.drop-help__list .simplebar-content div')

        // input.addEventListener('input', () => {
        //     if (input.value.length > 1) {
        //         block.classList.add('_active')
        //     } else {
        //         block.classList.remove('_active')
        //     }


        // })
        for (const item of list) {
            item.addEventListener('click', () => {
                input.value = item.textContent
                block.classList.remove('_active')
            })
        }
    }
    document.addEventListener('click', (e) => {
        if (!e.target.closest('.drop-help')) document.querySelectorAll('.drop-help').forEach(e => e.classList.remove('_active'))
    })
}

function loadFile() {
    const input = document.getElementById('main-profile-photo')
    if (input)

        input.addEventListener('change', function () {
            const errorHtml = document.getElementById('errorFile')

            if (input.files[0].size <= 368640) {
                document.getElementById('profile-img').setAttribute('src', URL.createObjectURL(input.files[0]));
                errorHtml.classList.remove('active')
            } else {
                errorHtml.classList.add('active')
            }
        });
}
// function scrollAddForm() {
//     const form = document.querySelector('#add_group_chat .custom-select-panel')
//     if (form)
//         new SimpleBar(form, {
//             autoHide: false,
//         });

// }
// setTimeout(scrollAddForm, 1000)
eventsSlider();
setFilled();
gallerySlider();
closeFancybox()
dropRegion()
loadFile()
document.addEventListener('DOMContentLoaded', function () {
    reviewsSlider();
    phoneMask();
    customSelectBlocks();
    scrolledEl();
    // initTimers();
    initRegisterForm();
    breadcrumbs();
    animateBottomReviews();
    animateTopDocument();
    setCookie();
    initModalVideo();
    validateQuestionForm();
    initProfileForms();
    timerInt()
    changeCountry()
    changeCity()
});